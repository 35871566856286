// components/Projects.js
import React, { useState } from 'react';
import tagStyles from '../utils/tagStyles';
import { getLanguageStyle } from '../utils/languageStyles';
import projectTypes from '../utils/projectTypes';
import { useSwipeable } from 'react-swipeable';
import { TextField, IconButton, InputAdornment, Checkbox, Box, Grid, Container, Card, CardContent, Typography, Button, Select, MenuItem, Modal, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicIcon from '@mui/icons-material/Public';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import Apollo Client dependencies
import { gql, useMutation, useQuery } from '@apollo/client';

// GraphQL query to fetch all projects
const GET_PROJECTS_AND_COMMIT = gql`
  query GetProjectsAndCommit {
    projects {
      id
      title
      description
      description2
      year
      type
      image
      views
      link
      languages
    }
    lastCommitDate
  }
`;

// GraphQL mutation for incrementing project views
const INCREMENT_PROJECT_VIEWS = gql`
  mutation IncrementProjectViews($id: ID!) {
    incrementProjectViews(id: $id) {
      id
      views
    }
  }
`;

// Helper function to calculate the time difference
const calculateTimeDifference = (lastUpdatedDate) => {
  const now = new Date();
  const lastUpdated = new Date(lastUpdatedDate);
  const timeDiffInMs = now - lastUpdated;

  const minutes = Math.floor(timeDiffInMs / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day(s) ago`;
  } else if (hours > 0) {
    return `${hours} hour(s) ago`;
  } else {
    return `${minutes} minute(s) ago`;
  }
};

// Helper function to calculate the percentage for languages
const calculateLanguagePercentage = (languages) => {
  const totalBytes = Object.values(languages).reduce((sum, value) => sum + value, 0);
  const percentages = {};
  for (const [lang, bytes] of Object.entries(languages)) {
    percentages[lang] = ((bytes / totalBytes) * 100).toFixed(1);
  }
  return percentages;
};

const Projects = () => {
  const [filter, setFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSearchTerm, setFilterSearchTerm] = useState('');
  const [showLanguages, setShowLanguages] = useState(false);

  const toggleView = () => {
    setShowLanguages(!showLanguages);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNextProject(), 
    onSwipedRight: () => goToPreviousProject(), 
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Fetch projects and set pollInterval to refetch every 5 seconds
  const { loading, error, data } = useQuery(GET_PROJECTS_AND_COMMIT, {
    pollInterval: 5000, // Fetch the data every 5 seconds
  });

  // Apollo mutation hook for incrementing views
  const [incrementViews] = useMutation(INCREMENT_PROJECT_VIEWS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching projects!</p>;

  const projects = data.projects; 
  const lastUpdated = calculateTimeDifference(data.lastCommitDate);

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilter(typeof value === 'string' ? value.split(',') : value);
  };

  // Handle opening the modal and incrementing views via GraphQL
  const handleOpenModal = async (index) => {
    try {
      await incrementViews({ variables: { id: projects[index].id } });
      setSelectedProjectIndex(index);
      setOpenModal(true);
    } catch (error) {
      console.error('Error incrementing views:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFilterSearchChange = (event) => {
    event.stopPropagation(); 
    setFilterSearchTerm(event.target.value);
  };

  const filteredProjects = projects.filter(
    (project) =>
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter.length === 0 || filter.includes(project.type))
  );

  const goToNextProject = () => {
    if (selectedProjectIndex < filteredProjects.length - 1) {
      setSelectedProjectIndex(selectedProjectIndex + 1);
    }
  };

  const goToPreviousProject = () => {
    if (selectedProjectIndex > 0) {
      setSelectedProjectIndex(selectedProjectIndex - 1);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        mb: 4,
        backgroundColor: '#faf3e0',
        borderRadius: 2,
        padding: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
        <Typography variant="h4" gutterBottom>
          Projects
        </Typography>
        {/* Display Last Updated */}
        <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#666', textAlign: 'right' }}>
          Last updated: <br /> {lastUpdated}
        </Typography>
      </Box>

      {/* Filter Dropdown */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Select
          multiple
          value={filter}
          onChange={handleFilterChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>All</em>;
            }
            return selected.join(', '); 
          }}
          sx={{
            width: '225px',
            height: '36px',
            mt: 0.5,
            '.MuiOutlinedInput-root': {
              padding: '6px',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: '180px',
                mt: 0,
                mb: 0,
                backgroundColor: '#faf3e0',
                border: '1px solid #ccc',
                borderRadius: 1,
                padding: 0,
              },
            },
          }}
        >
          {/* Search bar inside dropdown */}
          <MenuItem disableRipple disableTouchRipple>
            <TextField
              variant="outlined"
              placeholder="Category"
              value={filterSearchTerm}
              onChange={handleFilterSearchChange}
              onClick={(event) => event.stopPropagation()}
              onKeyDown={(event) => event.stopPropagation()}
              size="small"
              sx={{
                width: '100%',
                height: '36px',
                '.MuiOutlinedInput-root': {
                  height: '36px',
                  fontSize: '0.875rem',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: filterSearchTerm && (
                  <IconButton onClick={() => setFilterSearchTerm('')} sx={{ marginRight: '-12px' }}>
                    <ClearIcon />
                  </IconButton>
                ),
                sx: {
                  height: '36px',
                  paddingLeft: '4px',
                },
              }}
            />
          </MenuItem>

          {/* Filtered options with checkboxes */}
          {projectTypes
            .filter((type) => type.toLowerCase().includes(filterSearchTerm.toLowerCase())) 
            .map((type) => (
              <MenuItem key={type} value={type} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Checkbox checked={filter.indexOf(type) > -1} />
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '10px',
                    padding: '3px 10px',
                    ...tagStyles(type),
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                >
                  {type.toUpperCase()}
                </Button>
              </MenuItem>
            ))}
        </Select>

        {/* Search bar with clear button */}
        <TextField
          variant="outlined"
          placeholder="Project Name"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              handleClearSearch();
            }
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: searchTerm && (
              <IconButton onClick={handleClearSearch} sx={{ marginRight: '-12px' }}>
                <ClearIcon />
              </IconButton>
            ),
            sx: {
              height: '36px',
              paddingLeft: '4px',
            },
          }}
          inputProps={{
            style: { paddingLeft: '6px' }, 
            autoComplete: 'off',
          }}
          autoComplete="off" 
          sx={{
            width: '200px',
            mt: 0.5,
            '.MuiOutlinedInput-root': {
              height: '36px',
              fontSize: '0.875rem',
            },
          }}
        />
      </Box>

      {/* Projects Grid */}
      <Grid container spacing={3}>
        {filteredProjects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: 3,
                border: 1,
                borderRadius: '10px',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  zIndex: 1,
                },
                '@media (max-width: 768px)': {
                  '&:hover': {
                    transform: 'none',
                    boxShadow: 'none',
                  },
                },
              }}
              onClick={() => handleOpenModal(index)}
            >
              <img
                src={project.image}
                alt={project.title}
                style={{ width: '100%', height: '200px', objectFit: 'cover', imageRendering: 'crisp-edges', borderBottom: '1px solid #ccc' }}
              />
              <CardContent sx={{ position: 'relative', minHeight: '120px' }}>
                <Typography variant="h5" className="title" sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 'clamp(20px, 2vw, 24px)',
                }}>
                  {project.title}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    fontSize: '0.875rem',
                    color: '#666',
                  }}
                >
                  {project.description}
                </Typography>

                {/* Move year to the left */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    {project.year}
                  </Typography>
                </Box>

                {/* Flexbox container for the tag */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1, gap: '5px' }}>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: '10px',
                      padding: '3px 10px',
                      ...tagStyles(project.type),
                      fontWeight: 'bold',
                      fontSize: '0.8rem',
                      textTransform: project.type === 'App' ? 'capitalize' : 'uppercase',
                    }}
                  >
                    {project.type === 'App' ? 'APP' : project.type.toUpperCase()}
                  </Button>
                </Box>

                {/* Views count positioned with padding from the right */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <VisibilityIcon sx={{ color: 'black', mr: 1 }} />
                  <Typography variant="body2">
                    {project.views} {project.views === 1 ? 'view' : 'views'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for project details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          '&:focus-visible': { outline: 'none' },
          '.MuiBackdrop-root': { outline: 'none' },
        }}
      >
        <Box {...handlers}          
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 1,
            pb: 1,
            width: { xs: '85%', sm: 400 },
            outline: 'none',
            maxHeight: '90vh',
            overflowY: 'auto',
            textAlign: 'center',
          }}
        >
          {selectedProjectIndex !== null && (
            <>
              <Typography variant="h4" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
                {filteredProjects[selectedProjectIndex].title}
              </Typography>

              {showLanguages ? (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'normal',
                      mb: 1,
                      ml: '10%',
                      color: '#666',
                      textAlign: 'left',
                    }}
                  >
                    Languages used
                  </Typography>
                  {filteredProjects[selectedProjectIndex].languages ? (
                    <>
                      {/* Progress Bar */}
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1 }}>
                        <Box
                          sx={{
                            position: 'relative',
                            height: '8px',
                            width: '80%',
                            backgroundColor: '#e1e4e8',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            display: 'flex',
                            gap: '2px', 
                          }}
                        >
                          {Object.entries(calculateLanguagePercentage(filteredProjects[selectedProjectIndex].languages)).map(
                            ([lang, percent], index) => (
                              <Box
                                key={index}
                                sx={{
                                  height: '100%',
                                  width: `${percent}%`,
                                  backgroundColor: getLanguageStyle(lang).backgroundColor,
                                }}
                              />
                            )
                          )}
                        </Box>
                      </Box>

                      {/* Language labels */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start', 
                          alignItems: 'center',
                          gap: '10px', 
                          flexWrap: 'wrap',
                          maxWidth: '80%',
                          margin: '0 auto', 
                          rowGap: '0px',
                        }}
                      >
                        {Object.entries(calculateLanguagePercentage(filteredProjects[selectedProjectIndex].languages)).map(
                          ([lang, percent], index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                              }}
                            >
                              <Box
                                sx={{
                                  height: '10px',
                                  width: '10px',
                                  backgroundColor: getLanguageStyle(lang).backgroundColor,
                                  borderRadius: '50%',
                                  marginRight: '8px',
                                }}
                              />
                              <Typography variant="body2" sx={{ marginRight: '10px' }}>
                                <strong>{lang}</strong> {percent}%
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body2">No language data available</Typography>
                  )}
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                    textAlign: 'justify',
                    maxWidth: '85%',
                    margin: '0 auto',
                    lineHeight: '1.6',
                  }}
                >
                  {filteredProjects[selectedProjectIndex].description2}
                </Typography>
              )}

              {/* Dots to switch between views */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                {/* First dot (Project description view) */}
                <Box
                  sx={{
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: !showLanguages ? '#000' : '#ccc',
                    cursor: 'pointer',
                    mx: 1,
                    transition: 'transform 0.3s',
                    animation: showLanguages ? 'pulse 2s infinite' : 'none', 
                    '@keyframes pulse': {
                      '0%': {
                        transform: 'scale(1)',
                      },
                      '50%': {
                        transform: 'scale(1.3)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                      },
                    },
                    '&:hover': {
                      transform: 'scale(1.2)',
                    },
                  }}
                  onClick={() => setShowLanguages(false)}
                />
                {/* Second dot (Languages view) */}
                <Box
                  sx={{
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: showLanguages ? '#000' : '#ccc',
                    cursor: 'pointer',
                    mx: 1,
                    transition: 'transform 0.3s',
                    animation: !showLanguages ? 'pulse 2s infinite' : 'none',
                    '@keyframes pulse': {
                      '0%': {
                        transform: 'scale(1)',
                      },
                      '50%': {
                        transform: 'scale(1.3)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                      },
                    },
                    '&:hover': {
                      transform: 'scale(1.2)',
                    },
                  }}
                  onClick={() => setShowLanguages(true)}
                />
              </Box>

              {/* Project details layout */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between', // Changed to 'space-between' for equal spacing
                  alignItems: 'center', // Ensures vertical centering
                  mt: 3,
                  px: 1,
                  textAlign: 'center',
                }}
              >
                {/* Type Button */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    sx={{
                      ...tagStyles(filteredProjects[selectedProjectIndex].type, 'modal'),
                      padding: '4px 12px', // Adjusted padding
                      minHeight: '36px',   // Ensures consistent button height
                      marginBottom: '0px', // Removed additional bottom margin
                    }}
                  >
                    {filteredProjects[selectedProjectIndex].type}
                  </Button>
                </Box>

                {/* Year */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: '1rem', lineHeight: 2 }}>
                    {filteredProjects[selectedProjectIndex].year}
                  </Typography>
                </Box>

                {/* Link */}
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <Tooltip title={filteredProjects[selectedProjectIndex].link ? filteredProjects[selectedProjectIndex].link : 'No link available'}>
                    <a
                      href={filteredProjects[selectedProjectIndex].link || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <PublicIcon
                        sx={{
                          color: filteredProjects[selectedProjectIndex].link ? '#1E90FF' : 'red',
                          cursor: filteredProjects[selectedProjectIndex].link ? 'pointer' : 'not-allowed',
                          fontSize: '2rem',
                          verticalAlign: 'middle',
                        }}
                      />
                    </a>
                  </Tooltip>
                </Box>
              </Box>

              {/* Pagination display */}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <IconButton 
                  onClick={goToPreviousProject} 
                  disabled={selectedProjectIndex === 0} 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: 40, 
                    height: 40,                    
                    padding: 0,
                    mr: 1
                  }}
                >
                  <ArrowBackIosIcon 
                    sx={{
                      fontSize: '1rem', 
                      marginLeft: '5px'
                    }} 
                  />
                </IconButton>

                <Typography variant="body2">
                  {`${selectedProjectIndex + 1} of ${filteredProjects.length}`}
                </Typography>

                <IconButton 
                  onClick={goToNextProject} 
                  disabled={selectedProjectIndex === filteredProjects.length - 1} 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: 40, 
                    height: 40,                
                    padding: 0,
                    ml: 1
                  }}
                >
                  <ArrowForwardIosIcon 
                    sx={{
                      fontSize: '1rem', 
                      marginRight: '0px'
                    }} 
                  />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Projects;
