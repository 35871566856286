// src/utils/tagStyles.js

const tagStyles = (type, context = 'card') => {
  if (context === 'modal') {
    switch (type) {
      case 'App':
        return {
          bgcolor: '#1976d2',
          color: '#FFF',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      case 'Automation':
        return {
          bgcolor: '#00796B',
          color: '#FFF',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      case 'Other':
        return {
          bgcolor: '#FFCDD2',
          color: '#C62828',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      case 'Website':
        return {
          bgcolor: '#FFA500',
          color: '#FFF',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      case 'Analysis': 
        return {
          bgcolor: '#8A2BE2',  
          color: '#FFF',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      case 'Transcribe': 
        return {
          bgcolor: '#4CAF50', 
          color: '#FFF',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
      default:
        return {
          bgcolor: '#E0E0E0',
          color: '#000',
          borderRadius: 2,
          padding: '4px 12px',
          fontSize: '0.875rem',
          textTransform: 'none',
        };
    }
  } else {
    switch (type) {
      case 'App':
        return {
          backgroundColor: '#E0F7FA',
          color: '#1976d2',
          borderColor: '#1976d2'
        };
      case 'Automation':
        return {
          backgroundColor: '#E0F7FA',
          color: '#00796B',
          borderColor: '#00796B'
        };
      case 'Other':
        return {
          backgroundColor: '#FFEBEE',
          color: '#C62828',
          borderColor: '#C62828'
        };
      case 'Website':
        return {
          backgroundColor: '#FFF5E1',
          color: '#FFA500',
          borderColor: '#FFA500'
        };
      case 'Analysis': 
        return {
          backgroundColor: '#E6E6FA', 
          color: '#8A2BE2',  
          borderColor: '#8A2BE2'
        };
        case 'Transcribe':
          return {
          backgroundColor: '#E8F5E9', 
          color: '#4CAF50',
          borderColor: '#4CAF50'
        };        
      default:
        return {
          backgroundColor: '#E0E0E0',
          color: '#000',
          borderColor: '#000'
        };
    }
  }
};

export default tagStyles;
